import '../css/socialMedia.min.css';

import instagram from '../../resources/icons/instagram.svg';
import telegram from '../../resources/icons/telegram.svg';
import facebook from '../../resources/icons/facebook.svg';
import tiktok from '../../resources/icons/tiktok.svg';
import youtube from '../../resources/icons/youtube.svg';
import linkedin from '../../resources/icons/linkedin.svg';

const SocialMedia = () => {
    return (
        <div className="social">
            <div className="container">
                <div className="title">Social Media Management</div>
                <div className="descr">Keeping your brand on social media is the key to building the trust. When people see you on multiple social media, subconsciously they think they know you personally. In addition, it’s a modern way to get more clients</div>
                <div className="items_block">
                    <div className="card">
                        <img src={instagram} alt="instagram" className="icon" />
                        <div className="card_content">
                            <div className="card_content-title">INSTAGRAM</div>
                            <div className="card_content-text">His is the first place most people are going to check before contacting your business. Building custom modern visual with useful information overbeats most competitors. Fast growing feature “Reels” boosts accounts faster that anything else on Instagram</div>
                        </div>
                    </div>
                    <div className="card">
                        <img src={telegram} alt="telegram" className="icon" />
                        <div className="card_content">
                            <div className="card_content-title">TELEGRAM</div>
                            <div className="card_content-text">Give useful information to people to prove that you are professional in your niche. Also, regular messages will remind potential clients about your existence.</div>
                        </div>
                    </div>
                    <div className="card">
                        <img src={facebook} alt="facebook" className="icon" />
                        <div className="card_content">
                            <div className="card_content-title">FACEBOOK</div>
                            <div className="card_content-text">This is a perfect place to attract certain types of audience, It’s also a control centre of ads for Instagram. The first platform that every business needs</div>
                        </div>
                    </div>
                    <div className="card">
                        <img src={youtube} alt="youtube" className="icon" />
                        <div className="card_content">
                            <div className="card_content-title">YOUTUBE</div>
                            <div className="card_content-text">Youtube shorts is a great tool that is developing rapidly fast. Don’t miss out on a chance to get in while it’s not overpacked</div>
                        </div>
                    </div>
                    <div className="card">
                        <img src={tiktok} alt="tiktok" className="icon" />
                        <div className="card_content">
                            <div className="card_content-title">TIK TOK</div>
                            <div className="card_content-text">Show people your product or service and the emotions your clients will get after the contact with your business. Great place to find the a lot of customers quick</div>
                        </div>
                    </div>
                    <div className="card">
                        <img src={linkedin} alt="linkedin" className="icon" />
                        <div className="card_content">
                            <div className="card_content-title">LINKEDIN</div>
                            <div className="card_content-text">Professional page will help to grow the opposite side of your business from other social media. Look for employees and improve your brand trust and awareness here.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SocialMedia;