import { Helmet, HelmetProvider } from 'react-helmet-async';

import Contacts from "../contacts/Contacts";

const ContactsPage = () => {
    return (
        <>         
            <HelmetProvider>
                <Helmet>
                    <meta
                        name="description"
                        content="Bring your business to the next level"
                        />
                    <title>Contact us</title>
                </Helmet> 
            </HelmetProvider>  
            <Contacts/>
        </>
    )
}

export default ContactsPage;