import { Helmet, HelmetProvider } from 'react-helmet-async';

import Thanks from "../thanks/Thanks";

const ThanksPage = () => {
    return (
        <>         
            <HelmetProvider>
                <Helmet>
                    <meta
                        name="description"
                        content="Thank you"
                        />
                    <title>Thanks Page</title>
                </Helmet>    
            </HelmetProvider> 
            <Thanks/>
        </>
    )
}

export default ThanksPage;