import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import {HomePage, PlansPage, ClubPage, ContactsPage, NotFoundPage, ThanksPage} from '../pages';
import AppHeader from '../appHeader/AppHeader';


import '../css/app.min.css';

function App() {
    return (
        <Router>
            <div className="app">
                <AppHeader/>
                <Routes>
                    <Route path='/' element={<HomePage/>}/>
                    <Route path='/plans' element={<PlansPage/>}/>
                    <Route path='/club' element={<ClubPage/>}/>
                    <Route path='/contacts' element={<ContactsPage/>}/>
                    <Route path='*' element={<NotFoundPage />}/>
                    <Route path='/thanks' element={<ThanksPage />}/>
                </Routes>
            </div>
        </Router>

    );
}

export default App;