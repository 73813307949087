import Slider from "react-slick";

import '../css/gettingToWork.min.css';

import miniRocket from '../../resources/icons/mini-rocket.svg';

const GettingToWork = () => {
    const settingsGettingToWork = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        fade: true
    };
    return (
        <div className="getting_to_work">
            <div className="container">
                <div className="title">GETTING TO WORK</div>
                <div className="slider_container_gettingToWork">
                    <img src={miniRocket} alt="" className="mini_rocket" />
                    <Slider {...settingsGettingToWork}>
                        <div className="slides__items_gettingToWork">
                            <div className="item__block_gettingToWork">
                                <div className="slide__title_gettingToWork">INITIAL CALL</div>
                                <div className="slide__descr_gettingToWork">Meeting each other, discussing details and setting the goals up. Understanding of the goals helps up choose ideal plan for getting the best results.</div>
                            </div>
                        </div>
                        <div className="slides__items_gettingToWork">
                            <div className="item__block_gettingToWork">
                                <div className="slide__title_gettingToWork">PLAN</div>
                                <div className="slide__descr_gettingToWork">We analyze market, competitors and create a detailed personalized plan for your business and present it to you. It includes ideas, main focus directions, time frames and more. Together, we will add more changes.</div>
                            </div>
                        </div>
                        <div className="slides__items_gettingToWork">
                            <div className="item__block_gettingToWork">
                                <div className="slide__title_gettingToWork">DESIGNS</div>
                                <div className="slide__descr_gettingToWork">Presenting to you designs and concepts for adding changes and making it perfect.</div>
                            </div>
                        </div>
                        <div className="slides__items_gettingToWork">
                            <div className="item__block_gettingToWork">
                                <div className="slide__title_gettingToWork">GAME BEGINS</div>
                                <div className="slide__descr_gettingToWork">Presenting designs and concepts to you for additional corrections and making it perfect.</div>
                            </div>
                        </div>
                        <div className="slides__items_gettingToWork">
                            <div className="item__block_gettingToWork">
                                <div className="slide__title_gettingToWork">CORRECTIONS</div>
                                <div className="slide__descr_gettingToWork">Additional corrections along the way.</div>
                            </div>
                        </div>
                        <div className="slides__items_gettingToWork">
                            <div className="item__block_gettingToWork">
                                <div className="slide__title_gettingToWork">PROCESS</div>
                                <div className="slide__descr_gettingToWork">Presenting working website and Social Media. Final changes and corrections.</div>
                            </div>
                        </div>
                        <div className="slides__items_gettingToWork">
                            <div className="item__block_gettingToWork">
                                <div className="slide__title_gettingToWork">RESULTS</div>
                                <div className="slide__descr_gettingToWork">Our goal is to build long-term relationships with our clients. That’s why we are working hard to actually achieve results. Everything that’s done in previous steps - starts getting results</div>
                            </div>
                        </div>
                        <div className="slides__items_gettingToWork">
                            <div className="item__block_gettingToWork">
                                <div className="slide__title_gettingToWork">SUPPORT</div>
                                <div className="slide__descr_gettingToWork">The more time we work together, the better we understand your brand and needs. We do regular updates for staying modern and keep up with trends.</div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default GettingToWork;