import '../css/plansMore.min.css';

import plansAdvanced from '../../resources/img/plans-new.png';
import close from '../../resources/icons/close.svg';
import check from '../../resources/icons/check.svg';
import noCheck from '../../resources/icons/nocheck.svg';

const AdvancedPlan = ({ setIsOpenAdvancedPlan }) => {
    return (
      <>
        <div className="modal_wrapper" onClick={() => setIsOpenAdvancedPlan(false)} />
        <div className="modal_block_center">
            <div className="plan_item modal_block">
                <div className="modal_block_scroll">
                    <button className="closeBtn" onClick={() => setIsOpenAdvancedPlan(false)}>
                        <img src={close} alt="" />
                    </button>
                    <div className="plan_img modal_img">
                        <img src={plansAdvanced} alt="" />
                    </div>
                    <div className="plan_title modal_title">ADVANCED</div>
                    <div className="plan_list modal_list">
                        <ul>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Logo</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Business card</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Advanced website</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Custom design by top designer</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Quality hosting</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Simple SEO</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Support time (7am-10pm)</li>
                            </div>
                        </ul>
                        <ul>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Google Ads</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Custom Domain</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Bi-weekly consultations</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Licensed products</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Access to VBC</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>SSL certificate</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Marketing - targeting</li>
                            </div>
                        </ul>
                        <ul>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>CRM</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Facebook / Instagram Ads</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Adjustabble design of web, logo, SM</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Advanced SEO</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>1-2 crazy marketing idea</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>30 posts per month</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Custom edited videos</li>
                            </div>
                        </ul>
                        <ul>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Visual concept</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Weekly content plan</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Trends control</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Creating custom content</li>
                            </div>
                            <div className="list_item">
                                <img src={check} alt="" className="list_item_icon" />
                                <li>Latest technologies</li>
                            </div>
                            <div className="list_item">
                                <img src={noCheck} alt="" className="list_item_icon" />
                                <li>Custom HTML Email template</li>
                            </div>
                            <div className="list_item">
                                <img src={noCheck} alt="" className="list_item_icon" />
                                <li>Email automatization</li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
  };
  
  export default AdvancedPlan;