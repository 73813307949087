import ContactForm from '../forms/ContactForm';

import '../css/formSection.min.css';

const FormSection = () => {
    return (
        <div className="form_section">
            <div className="container">
                <div className="content_form">
                    <div className="hero_content form_section_content">
                        <div className="hero_title form_section_title">Bring your business to the next level</div>
                    </div>
                    <div className="form_block">
                        <div className="form_hero">
                            <div className="contacts_info_content content_form_title">CONTACT US</div>
                            <ContactForm/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormSection;