import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import '../css/contacts.min.css';

/* eslint-disable */
const ContactForm = () => {
    const token = '6035584722:AAFsgMYISo9MAYoR9Ho4ay0hod3qBHwGtwE';
    const chatId = '-894003180';

    const phoneRegExp = /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g
    const nameRegExp = /^[A-Za-z ]*$/

    const redirectToThanksPage = () => {
        window.location.href = "/thanks"
    }
    
    return (
        <Formik
        initialValues = {{
            name: '',
            phone: '',
            email: '',
            text: ''
        }}
        validationSchema = {Yup.object({
            name: Yup.string()
                    .min(2, 'Please enter valid name')
                    .matches(nameRegExp, 'Please enter valid name')
                    .required('Name is a required field'),
            email: Yup.string()
                    .email('Email is not valid')
                    .required('Email is required'),
            text: Yup.string()
                    .min(10, 'Minimum 10 symbol'),
            phone: Yup.string()
                    .matches(phoneRegExp, 'A phone number is not valid')
                    .min(8)
                    .required('A phone number is required'),
        })}       
        onSubmit = {values => fetch(`
                                https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text= Name: ${values.name.replaceAll(' ', '%20')} Email: ${values.email.replaceAll(' ', '%20')} Phone: ${values.phone.replaceAll(' ', '%20')} Notes: ${values.text.replaceAll(' ', '%20').replaceAll('\n', '%0A')}`)
                                .then((response) => response.json())
                                .then((values) => values, values.phone = '', values.text = '', values.email = '', values.name = '')
                                .than(redirectToThanksPage())}
        >
            <Form className="form" >
                <label htmlFor="name" className="label_form">
                    <Field 
                        id="name"
                        type="text" 
                        name="name" 
                        placeholder="Name"/>
                </label>
                <ErrorMessage component="div" className="error_validation" name="name"/>
                <label htmlFor="phone" className="label_form">
                    <Field 
                        id="phone"
                        type="tel" 
                        name="phone" 
                        placeholder="Phone"/>
                </label>
                <ErrorMessage component="div" className="error_validation" name="phone"/>
                <label htmlFor="email" className="label_form">
                    <Field 
                        id="email"
                        type="email" 
                        name="email" 
                        placeholder="Email"/>
                </label>
                <ErrorMessage component="div" className="error_validation" name="email"/>
                <label htmlFor="text" className="label_form">
                    <Field 
                        id="text" 
                        name="text"
                        type="text" 
                        as="textarea"
                        placeholder="Notes"/>
                </label>
                <ErrorMessage component="div" className="error_validation" name="text"/>
                <button className="hero_btn submit-btn send-form" type="submit">Send</button>
            </Form>
        </Formik>
    )
}

export default ContactForm;