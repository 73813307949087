import { NavLink } from 'react-router-dom';

import '../css/notFound.min.css';

import notFound from '../../resources/img/404.svg';

const NotFound = () => {
    return (
        <div className="not_found">
            <div className="container">
                <div className="not_found_wrapper">
                    <div className="not_found_content">
                        <div className="not_found_title">Oops,</div>
                        <div className="not_found_subtitle">you have lost in space...</div>
                        <div className="not_found_subtext">Sorry, we are not able to find what you were looking for.</div>
                        <NavLink
                        end
                        to="/">
                            <button className="hero_btn not_found_btn">GO HOME</button>
                        </NavLink>
                    </div>
                    <img src={notFound} alt="" className="error_image" />
                </div>
            </div>
        </div>
    );
}

export default NotFound;