import { NavLink } from 'react-router-dom';

import '../css/appHeader.min.css';

const AppHeader = () => {
    let activeClassName = "line_active";
    return (
        <div className="header">
            <div className="container">
                <div className="menu_nav">
                    <div className="nav_links">
                        <NavLink 
                            end 
                            to="/"
                            className={({ isActive }) =>
                                isActive ? activeClassName : undefined
                            }>
                                home
                        </NavLink>
                    </div>
                    <div className="nav_links">
                        <NavLink 
                            end 
                            to="/plans"
                            className={({ isActive }) =>
                                isActive ? activeClassName : undefined
                            }>
                                plans
                        </NavLink>
                    </div>
                    <div className="nav_links">
                        <NavLink 
                            end 
                            to="/club"
                            className={({ isActive }) =>
                                isActive ? activeClassName : undefined
                            }>
                                club
                        </NavLink>
                    </div>
                    <div className="nav_links">
                        <NavLink 
                            end 
                            to="/contacts"
                            className={({ isActive }) =>
                                isActive ? activeClassName : undefined
                            }>
                                contact us
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppHeader;