import { NavLink } from 'react-router-dom';

import '../css/hero.min.css';
import spaceman from '../../resources/img/spaceman.svg';
import rocket from '../../resources/img/rocket.svg';
import moon from '../../resources/img/moon2.svg';


const Hero = () => {
    return (
        <div className="hero">
            <div className="container">
                <div className="hero_wrapper">
                    <div className="hero_content">
                        <div className="hero_title">VELLKUNIO</div>
                        <div className="hero_subtitle">digital</div>
                        <div className="hero_descr">Bring your business to the top level by going digital with us. Leave no chance to the competitors</div>
                        <div className="btn_wrap">
                            <NavLink
                            end
                            to="/contacts">
                                <button className="hero_btn">What?</button>
                            </NavLink>
                            <div className="btn_shadow"></div>
                        </div>
                    </div>
                    <div className="hero_image">
                        <div className="hero_decoration">
                            <img src={spaceman} alt="spaceman" className="boy" />
                        </div>
                    </div>
                </div>
            </div>
            <img src={rocket} alt="rocket" className="rocket" />
            <img src={moon} alt="moon" className="moon" />
        </div>
    );
}

export default Hero;