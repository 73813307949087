import { NavLink } from 'react-router-dom';

import '../css/thanks.min.css';

import thanks from '../../resources/img/vbc.svg';

const Thanks = () => {
    return (
        <div className="not_found">
            <div className="container">
                <div className="not_found_wrapper tnx_wrapper">
                    <div className="not_found_content">
                        <div className="not_found_title tnx_title">Thank You!</div>
                        <div className="not_found_subtitle tnx_subtitle">We will call you</div>
                        <NavLink
                        end
                        to="/">
                            <button className="hero_btn not_found_btn">GO HOME</button>
                        </NavLink>
                    </div>
                    <img src={thanks} alt="" className="error_image tnx_image" />
                </div>
            </div>
        </div>
    );
}

export default Thanks;