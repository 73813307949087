import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import moon from '../../resources/img/moon.svg';
import saturn from '../../resources/img/saturn2.svg';
import astronaut from '../../resources/img/astronaut.svg';

import '../css/faq.min.css';


const Faq = () => {
    return ( 
        <div className="faq">
            <div className="container">
                <div className="title">FAQ</div>
                <div className="accordion_wrapp">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="question">Is this for offline business only?</div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="answer">No, this is for all businesses in the world.</div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="question">What is a VBC?</div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="answer">
                                Vellkunio Business Club is an alpha version of networking system exclusively only for our clients. Join it now for free with any package and any income before we set limitations in 2023. Soon, only the best of the best will be able to join. Limited capacity.
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="question">Do you have only packages or individual services too?</div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="answer">
                                We prefer to use packages as it’s individually created plans of services that give the best results together developed by our experts. Despite that, we still provide individual services too, but it has limitations like absence of access to VBC and more
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="question">How do you use AI (artificial intelligence) </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="answer">
                                We use only latest AI technologies for generating ideas, plans, copywriting, coding and more. In development: Automated system of creating the highest quality Reels, TikToks & YouTube shorts by AI to hit trends and awesome results.
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="question">How to start working wth Vellkunio Digital?</div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="answer">
                                It’s easier than you think. Leave your contact information or book a call with us on Contact us page for the easiest way or see “Getting to work” section for more information
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>

                    <div className="item_faq">
                        <img src={astronaut} alt="" className="astronaut" />
                    </div>
                </div>
            </div>
            <img src={moon} alt="" className="moon moon_faq" />
            <img src={saturn} alt="" className="saturn saturn_faq" />
        </div> 
    );
}

export default Faq;