import React, { useState } from "react";
import BeginnerPlan from "./BeginnerPlan";
import AdvancedPlan from "./AdvancedPlan";
import AdvancedProPlan from "./AdvancedProPlan";


import '../css/plans.min.css';

import plansBeginer from '../../resources/img/plans-beginner.png';
import plansAdvanced from '../../resources/img/plans-new.png';
import plansAdvancedPro from '../../resources/img/plans-pro.png';
import check from '../../resources/icons/check.svg';
import noCheck from '../../resources/icons/nocheck.svg';
import arrow from '../../resources/icons/arrow.svg';

const Plans = () => {
    const [isOpenBeginnerPlan, setIsOpenBeginnerPlan] = useState(false);
    const [isOpenAdvancedPlan, setIsOpenAdvancedPlan] = useState(false);
    const [isOpenAdvancedProPlan, setIsOpenAdvancedProPlan] = useState(false);
    return ( 
        <div className="plans">
            <div className="container">
                <div className="title">Plans</div>
                <div className="plans_wrap">
                    <div className="plan_item">
                        <div className="plan_img">
                            <img src={plansBeginer} alt="" />
                        </div>
                        <div className="plan_title">BEGINNER</div>
                        <div className="plan_descr">(only must have)</div>
                        <div className="plan_list">
                            <ul>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Logo</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Business card</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Advanced website</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Custom design by top designer</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Quality hosting</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Simple SEO</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Custom Domain</li>
                                </div>
                            </ul>
                            <ul>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>SSL certificate</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Marketing - targeting</li>
                                </div>
                                <div className="list_item">
                                    <img src={noCheck} alt="" className="list_item_icon" />
                                    <li>Email automatization</li>
                                </div>
                                <div className="list_item">
                                    <img src={noCheck} alt="" className="list_item_icon" />
                                    <li>Adjustabble design of web, logo, SM</li>
                                </div>
                                <div className="list_item">
                                    <img src={noCheck} alt="" className="list_item_icon" />
                                    <li>Advanced SEO</li>
                                </div>
                                <div className="list_item">
                                    <img src={noCheck} alt="" className="list_item_icon" />
                                    <li>1-2 crazy marketing idea</li>
                                </div>
                            </ul>
                        </div>
                        <button className="link_more" onClick={() => setIsOpenBeginnerPlan(true)}>
                            More
                            <img src={arrow} alt="" className="arrow_icon" />
                        </button>
                    </div>
                    <div className="plan_item">
                        <div className="plan_img">
                            <img src={plansAdvanced} alt="" />
                        </div>
                        <div className="plan_title">ADVANCED</div>
                        <div className="plan_descr">(only must have)</div>
                        <div className="plan_list">
                            <ul>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Logo</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Business card</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Advanced website</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Custom design by top designer</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Quality hosting</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Simple SEO</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Custom Domain</li>
                                </div>
                            </ul>
                            <ul>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>SSL certificate</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Marketing - targeting</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Email automatization</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Adjustabble design of web, logo, SM</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Advanced SEO</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>1-2 crazy marketing idea</li>
                                </div>
                            </ul>
                        </div>
                        <button className="link_more" onClick={() => setIsOpenAdvancedPlan(true)}>
                            More
                            <img src={arrow} alt="" className="arrow_icon" />
                        </button>
                    </div>
                    <div className="plan_item">
                        <div className="plan_img-pro">
                            <img src={plansAdvancedPro} alt="" />
                        </div>
                        <div className="plan_title">ADVANCED</div>
                        <div className="plan_descr">(only must have)</div>
                        <div className="plan_list">
                            <ul>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Logo</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Business card</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Advanced website</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Custom design by top designer</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Quality hosting</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Simple SEO</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Custom Domain</li>
                                </div>
                            </ul>
                            <ul>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>SSL certificate</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Marketing - targeting</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Email automatization</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Adjustabble design of web, logo, SM</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Advanced SEO</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>1-2 crazy marketing idea</li>
                                </div>
                            </ul>
                        </div>
                        <button className="link_more" onClick={() => setIsOpenAdvancedProPlan(true)}>
                            More
                            <img src={arrow} alt="" className="arrow_icon" />
                        </button>
                    </div>
                </div>
            </div>
            {isOpenBeginnerPlan && <BeginnerPlan setIsOpenBeginnerPlan={setIsOpenBeginnerPlan} />}
            {isOpenAdvancedPlan && <AdvancedPlan setIsOpenAdvancedPlan={setIsOpenAdvancedPlan} />}
            {isOpenAdvancedProPlan && <AdvancedProPlan setIsOpenAdvancedProPlan={setIsOpenAdvancedProPlan} />}
        </div> 
                        
    );
}

export default Plans;