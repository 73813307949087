
import '../css/plans.min.css';

import pack1 from '../../resources/img/pack1.svg';
import pack2 from '../../resources/img/pack2.svg';
import pack3 from '../../resources/img/pack3.svg';
import check from '../../resources/icons/check.svg';
import noCheck from '../../resources/icons/nocheck.svg';

const OnlySocialMedia = () => {
    return ( 
        <div className="plans">
            <div className="container">
                <div className="title">Only social media </div>
                <div className="plans_wrap plans_wrap-media">
                    <div className="plan_item media">
                        <div className="plan_img plan_img-media">
                            <img src={pack1} alt="" />
                        </div>
                        <div className="plan_title plan_title-media">Pack 1</div>
                        <div className="plan_descr">(only social media)</div>
                        <div className="plan_list plan_list-media">
                            <ul>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Facebook</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Instagram</li>
                                </div>
                                <div className="list_item">
                                    <img src={noCheck} alt="" className="list_item_icon" />
                                    <li>TikTok</li>
                                </div>
                                <div className="list_item">
                                    <img src={noCheck} alt="" className="list_item_icon" />
                                    <li>YouTube</li>
                                </div>
                                <div className="list_item">
                                    <img src={noCheck} alt="" className="list_item_icon" />
                                    <li>Telegram</li>
                                </div>
                                <div className="list_item">
                                    <img src={noCheck} alt="" className="list_item_icon" />
                                    <li>LinkedIn</li>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="plan_item media">
                        <div className="plan_img plan_img-media">
                            <img src={pack2} alt="" />
                        </div>
                        <div className="plan_title plan_title-media">Pack 2</div>
                        <div className="plan_descr">(only social media)</div>
                        <div className="plan_list plan_list-media">
                            <ul>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Facebook</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Instagram</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>TikTok</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>YouTube</li>
                                </div>
                                <div className="list_item">
                                    <img src={noCheck} alt="" className="list_item_icon" />
                                    <li>Telegram</li>
                                </div>
                                <div className="list_item">
                                    <img src={noCheck} alt="" className="list_item_icon" />
                                    <li>LinkedIn</li>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="plan_item media">
                        <div className="plan_img plan_img-media">
                            <img src={pack3} alt="" />
                        </div>
                        <div className="plan_title plan_title-media">Pack 3</div>
                        <div className="plan_descr">(only social media)</div>
                        <div className="plan_list plan_list-media">
                            <ul>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Facebook</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Instagram</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>TikTok</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>YouTube</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>Telegram</li>
                                </div>
                                <div className="list_item">
                                    <img src={check} alt="" className="list_item_icon" />
                                    <li>LinkedIn</li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        
    );
}

export default OnlySocialMedia;