import Slider from "react-slick";

import '../css/email.min.css';

import emaiBoy from '../../resources/img/astronaut-laptop.svg';
import satelite from '../../resources/img/satelite.svg';
import asteriod from '../../resources/img/asteroid.svg';


const Email = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return ( 
        <div className="email">
            <img src={satelite} alt="" className="satelite" />
            <img src={asteriod} alt="" className="asteroid" />
            <div className="container">
                <div className="title">Email</div>
                <div className="descr">Emails is a primary tool to communicate with current, potential and past clients without spending all your time on it. </div>
                <div className="email_block">
                    <div className="item_image">
                        <img src={emaiBoy} alt="" className="email_boy" />
                    </div>
                    <div className="slider_container">
                        <Slider {...settings}>
                            <div className="slides__items">
                                <div className="item__block">
                                    <div className="slide__title">Automatization</div>
                                    <div className="slide__descr">We create all possible scenarios and email bodies without overwhelming clients. Send Weekly reminders to people about your business. Send automatic responses. Configure </div>
                                </div>
                            </div>
                            <div className="slides__items">
                                <div className="item__block">
                                    <div className="slide__title">Business signature</div>
                                    <div className="slide__descr">We create optimized block of html code that will be converted to professional email every time you have to send email manually</div>
                                </div>
                            </div>
                            <div className="slides__items">
                                <div className="item__block">
                                    <div className="slide__title">Business signature</div>
                                    <div className="slide__descr">We create optimized block of html code that will be converted to professional email every time you have to send email manually</div>
                                </div>
                            </div>
                            <div className="slides__items">
                                <div className="item__block">
                                    <div className="slide__title">Business signature</div>
                                    <div className="slide__descr">We create optimized block of html code that will be converted to professional email every time you have to send email manually</div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default Email;