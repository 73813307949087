import { Helmet, HelmetProvider } from 'react-helmet-async';

import Plans from '../plans/Plans';
import OnlySocialMedia from '../plans/OnlySocialMedia';

const PlansPage = () => {
    return (
        <>        
            <HelmetProvider>
                <Helmet>
                    <meta
                        name="description"
                        content="Page with plans of our company"
                        />
                    <title>Plans page</title>
                </Helmet> 
            </HelmetProvider>              
            <Plans/>
            <OnlySocialMedia/>
        </>
    )
}

export default PlansPage;