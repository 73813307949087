import { Helmet, HelmetProvider } from 'react-helmet-async';

import Club from "../club/Club";

const ClubPage = () => {
    return (
        <>      
            <HelmetProvider>
                <Helmet>
                    <meta
                        name="description"
                        content="Close Networking Club for Business Owners"
                        />
                    <title>Vellkunio business club</title>
                </Helmet>  
            </HelmetProvider>       
            <Club/>
        </>
    )
}

export default ClubPage;