import '../css/services.min.css';

import moon from '../../resources/img/moon.svg';
import saturn from '../../resources/img/saturn2.svg';
import moonSmall from '../../resources/img/moon-s.svg';
import meditationBoy from '../../resources/img/meditating.svg';






const Services = () => {
    return (
        <div className="serveces">
            <div className="title">Services</div>
            <div className="container">
                <div className="meditation_boy">
                    <img src={meditationBoy} alt="meditation_boy" />
                </div>
                <div className="block_items">
                    <div className="item first" style={{justifyContent: "space-around"}}>
                        <div className="title_text">SMM</div>
                        <div className="title_text">powerful websites</div>
                    </div>

                    <div className="item">
                        <div className="title_text">artificial intelligence <br/> technologies</div>
                        <div className="title_text">regular updates of styles</div>
                    </div>

                    <div className="item">
                        <div className="title_text">modern approach that <br/> will last many years</div>
                        <div className="title_text">marketing & targeting</div>
                    </div>

                    <div className="item" style={{justifyContent: "space-around"}}>
                        <div className="title_text">outstanding design</div>
                        <div className="title_text">CRM & email <br/> automatizations</div>
                    </div>
                </div>
            </div>

            <img src={saturn} alt="" className="saturn" />
            <img src={moon} alt="" className="moon_m" />
            <img src={moonSmall} alt="" className="moon_s" />
        </div>
    );
}

export default Services;