import ContactForm from '../forms/ContactForm';

import '../css/contacts.min.css';

import facebook from '../../resources/icons/contacts/facebook.svg';
import tiktok from '../../resources/icons/contacts/tiktok.svg';
import instagam from '../../resources/icons/contacts/instagram.svg';
import telegram from '../../resources/icons/contacts/telegram.svg';
import saturn from '../../resources/img/saturn2.svg';
import moon from '../../resources/img/moon2.svg';


const Contacts = () => {
    return ( 
        <div className="contacts">
            <div className="container">
                <div className="contacts_wrapp">
                    <div className="contacts_info">
                        <div className="contacts_info_content">
                            <div className="item_content">City: London, ON</div>
                            <div className="item_content">Adress: Richmond St. </div>
                            <div className="item_content">Phone: <a href="tel:+12262241256">(226) 224-1256</a></div>
                            <div className="item_content">Email: <a href="mailto:vellkunio@gmail.com">vellkunio@gmail.com</a></div>
                            <div className="social_links">
                                <div className="item_content">Social:</div>
                                <div className="contacts_links">
                                    <a href="http://facebook.com" target="_blank" rel="noopener noreferrer">
                                        <img src={facebook} alt="facebook" />
                                    </a>
                                    <a href="https://www.instagram.com/vellkunio_digital/" target="_blank" rel="noopener noreferrer">
                                        <img src={instagam} alt="instagam" />
                                    </a>
                                    <a href="http://tiktok.com" target="_blank" rel="noopener noreferrer">
                                        <img src={tiktok} alt="tiktok" />
                                    </a>
                                    <a href="https://web.telegram.org/" target="_blank" rel="noopener noreferrer">
                                        <img src={telegram} alt="telegram" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="contacts_info_map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2918.5725567455825!2d-81.25307478438698!3d42.987277003252245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882ef31aea418407%3A0xb4e0503058f8da95!2sVellkunio%20Digital!5e0!3m2!1sen!2sua!4v1674069722562!5m2!1sen!2sua" style={{border:0,width:"100%",height:"100%",borderRadius:30}} title="Vellkunio digital" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    <div className="contacts_form">
                        <div className="contacts_info_content content_form">CONTACT US</div>
                        <ContactForm/>
                    </div>
                </div>
            </div>

            <img src={saturn} alt="" className="saturn contacts_saturn_decoration" />
            <img src={moon} alt="moon" className="moon contacts_moon_decoration" />
        </div> 
    );
}

export default Contacts;