import { Helmet, HelmetProvider } from 'react-helmet-async';

import NotFound from "../notFound/NotFound";

const NotFoundPage = () => {
    return (
        <>     
            <HelmetProvider>
                <Helmet>
                    <meta
                        name="description"
                        content="Not found"
                        />
                    <title>404 not found page</title>
                </Helmet>
            </HelmetProvider>      
            <NotFound/>
        </>
    )
}

export default NotFoundPage;