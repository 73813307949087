

import '../css/club.min.css';

import vbcImage from '../../resources/img/vbc.svg';

const Club = () => {
    return ( 
        <div className="club">
            <div className="container">
                <div className="title">VBC</div>
                <div className="subtitle_small">( Vellkunio Business Club )</div>
                <div className="subtitle vbc_subtitle">Close Networking Club for Business Owners</div>
                <div className="clubs_wrapp">
                    <img src={vbcImage} alt="" className="vbc_image" />
                    <div className="clubs_description">
                        <div className="clubs_description_content">
                            <div className="clubs_description_title">Description:</div>
                            <div className="clubs_description_text">Business club for entrepreneurs who want to strengthen their professional qualities and also improve the quality of their life.</div>
                        </div>
                    </div>
                    <div className="clubs_benefints">
                        <div className="clubs_description">
                            <div className="clubs_description_content">
                                <div className="clubs_benefints_title">Benefits:</div>
                                <ul className="clubs_benefints_list">
                                    <li className="list_item_vbc">Get motivated</li>
                                    <li className="list_item_vbc">Improve your surrounding </li>
                                    <li className="list_item_vbc">Ideas for business</li>
                                    <li className="list_item_vbc">New partnerships</li>
                                    <li className="list_item_vbc">Meet other business owners</li>
                                    <li className="list_item_vbc">Investment</li>
                                    <li className="list_item_vbc">Spend time with entrepreneurs</li>
                                    <li className="list_item_vbc">Update connections</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    );
}

export default Club;