import { Helmet, HelmetProvider } from 'react-helmet-async';

import Hero from '../heroSection/Hero';
import Services from '../servicesSection/Services';
import SocialMedia from '../socialMedia/SocialMedia';
import Faq from '../faq/Faq';
import Email from '../email/Email';
import GettingToWork from '../gettingToWork/GettingToWork';
import FormSection from '../formSection/FormSection';

const HomePage = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta
                        name="description"
                        content="Web company Vellkunio digital"
                        />
                    <title>Vellkunio digital</title>
                </Helmet>
            </HelmetProvider>
            <Hero/>
            <Services/>
            <SocialMedia/>
            <Faq/>
            <Email/>
            <GettingToWork/>
            <FormSection/>
        </>
    )
}

export default HomePage;